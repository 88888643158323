<template>
  <div class="box">
    
    <card v-if="title" :title="title" :ismore="false" @morelink="moreLink">
      <div class="notext" v-if="list.length == 0">暂无数据</div>
      <div class="hangbox flexspb" v-for="(item, index) in list" :key="index">
        <slot v-bind:row="{item:item,index:index}">
          <div class="flexcent" @click="detailLink(item.id, item.standardId)">
            <span class="leftspot">·</span>
            <span class="title" >{{ item.title || item.name }}</span>
          </div>
          <div class="time" v-if="item.createdTime">
            {{ item.createdTime| FormatDate('yyyy-MM-dd')}}
          </div>
        </slot>
      </div>
    </card>
    <div v-if="!title">
      <div class="notext" v-if="list.length == 0">暂无数据</div>
      <div class="hangbox flexspb" v-for="(item, index) in list" :key="index">
        <slot v-bind:row="{item:item,index:index}">
          <div class="flexcent" @click="detailLink(item.id, item.standardId)">
            <span class="leftspot">·</span>
            <span class="title" >{{ item.title || item.name }}</span>
          </div>
          <div class="time" v-if="item.createdTime">
            {{ item.createdTime| FormatDate('yyyy-MM-dd')}}
          </div>
        </slot>
      </div>
    </div>
    <div v-if="this.total">
      <pagin @change="handleChange" :current="current" :size="size" :total="total" />
    </div>
  </div>
</template>

<script>
import card from "@/components/all/card"; //封装的卡片样式
import pagin from "@/components/all/pagin"; //引入分页组件
export default {
  name: "pageList", //翻页列表
  props: {
    // 内容的数组
    list: Array,
    // 卡片标题
    title: String,
    // 详情路径
    detailPath: String,
    current: Number, //当前页码
    size: Number, //每页条数
    total: Number, //总数
  },
  components: {
    card,
    pagin,
  },
  data() {
    return {
      // current: 1, //当前页码
      // size: 4, //每页条数
      // total: 10, //总数
    };
  },
 
  methods: {
    // 点击更多进行跳转
    moreLink() {
      // console.log(" more link ", this.morePath)
      //  进行路由跳转
      this.until.jump.call(this, this.morePath, "push");
    },
    // 进行详情的跳转
    detailLink(id) {
      if( !this.detailPath) {
        console.error("-------- do not config " +  this.title +" detail path:" + this.detailPath)
        return 
      }
      var path = this.detailPath+"/"+id;
      console.log(" detail link ", path)
      //  进行路由跳转
      this.until.jump.call(this, path, "push");
    },
    handleChange(e) {
      // console.log("$$$$$$$$$$$$$ page list handleChange ", e)
      this.$emit("pageChanged", e);
    },
  },
};
</script>

<style scoped lang="less">

  // 当前页面宽高

  //   div{
  //    border: 2px solid #ee410c;
  //    padding: 20px;
  // }
  .box {
    width: 100%;
    height: 100%;
    padding: 0 0.3125rem;
    box-sizing: border-box;

    .notext{
      font-size: .875rem;
      margin: .3125rem;
    }
    /deep/ .cardbox {
      // 修改盒子内容
      .cardhead {
        .leftline {
          display: none;
        }
        .title {
          font-size: 1rem;
        }
      }
    }
    .hangbox:hover {
      // border: 2px solid #ee410c;
      // padding: 0.5rem 0;
      background: #f9f9fe ;
    }
    .hangbox {
      // height: 2rem;
      padding: 0.5rem 0 ;
      border-bottom: 1px solid #eee;

    }
    .time {
      font-size: 0.875rem;
    }
    .leftspot {
      margin: 0 0.3125rem 0 0.375rem;
      font-size: 2.5rem;
    }
    .title {
      margin-top: 0.375rem;
      color: #494949;
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .title:hover {
      color: #3d59ff;
      text-decoration: underline;
    }
    /deep/ .cardbox:hover {
      background-color: transparent;
    }
  }

  // @media screen and (min-width: 1000px) {}

  // @media screen and (max-width: 999px) {
  //   // 当前页面宽高
  //   .box {
  //     width: 100%;
  //     height: 100%;
  //     padding: 0 0.9375rem;
  //     margin-top: 0.625rem;
  //     box-sizing: border-box;
  //     /deep/ .cardbox {
  //       // 修改盒子内容
  //       .cardhead {
  //         .leftline {
  //           display: none;
  //         }
  //         .title {
  //           font-size: 0.875rem;
  //         }
  //       }
  //     }
  //     .hangbox {
  //       // height: 2rem;
  //       margin-top: 0.5rem;
  //       border-bottom: 1px solid #eee;
  //     }
  //     .time {
  //       font-size: 0.675rem;
  //     }
  //     .leftspot {
  //       margin: 0 0.3125rem 0 0.375rem;
  //       font-size: 2.5rem;
  //     }
  //     .title {
  //       margin-top: 0.375rem;
  //       color: #494949;
  //       font-size: 0.875rem;
  //       // width: 12.25rem;
  //       // padding-right: 1.25rem;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       white-space: nowrap;
  //     }

  //     /deep/ .cardbox:hover {
  //       background-color: transparent;
  //     }
  //   }
  // }
</style>
