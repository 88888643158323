<template>
  <div class="inputbox">
    <el-input
      placeholder="请输入关键字进行查询"
      v-model.trim="nowvalue"
      clearable
      @blur.prevent="search"
      @keyup.enter.native="search"
    >
      <el-button
        @click="search"
        slot="append"
        icon="el-icon-search"
      ></el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "search", //新闻详情
  props: {
    // 传入当前输入的值
    value: {
      typeof: [String],
    },
  },
  data() {
    return {
      nowvalue: "",
    };
  },
  created() {
    this.nowvalue = this.value;
  },
  methods: {
    // 点击按钮进行搜索
    search() {
      if(this.nowvalue != this.value)
        this.$emit("search", this.nowvalue);
      // else 
      //   console.log(" search text not changed ", this.nowvalue, this.value)
    },
    // input() {
    //   this.$emit("search", this.nowvalue);
    // },
  },
};
</script>


<style scoped lang="less">
@media screen and (min-width: 1000px) {
  .inputbox {
    width: 100%;
    height: 100%;
    /deep/ .el-input {
      width: 100%;
      height: 100%;
      input {
        width: 100%;
        height: 100%;
        padding: 0 1.875rem 0 0.9375rem;
        font-size: 0.875rem;
      }
      .el-input__suffix {
        .el-input__icon {
          width: 1.5rem;
          font-size: 1rem;
        }
      }
      button {
        width: 2.875rem;
        height: 2.1875rem;
        .el-icon-search {
          font-size: 0.9rem;
        }
      }
      button:hover {
        background-color: #e6e6e6;
        color: #ffffff;
      }
      button:active {
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 999px) {
   .inputbox {
    width: 100%;
    height: 100%;
    /deep/ .el-input {
      width: 100%;
      height: 100%;
      input {
        width: 100%;
        height: 100%;
        padding: 0 1.875rem 0 0.9375rem;
        font-size: 0.875rem;
      }
      .el-input__suffix {
        .el-input__icon {
          width: 1.5rem;
          font-size: 1rem;
        }
      }
      button {
        width: 2.875rem;
        height: 2.1875rem;
        .el-icon-search {
          font-size: 0.9rem;
        }
      }
      button:hover {
        background-color: #e6e6e6;
        color: #ffffff;
      }
      button:active {
        opacity: 0.7;
      }
    }
  }
}

</style>
